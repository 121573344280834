import React from "react";

// Image files
import webp from "../../images/nano_horz_color.webp";
import png1 from "../../images/nano_horz_color.png";
import png2 from "../../images/nano_horz_color-2x.png";
import png4 from "../../images/nano_horz_color-4x.png";
import jpg1 from "../../images/nano_horz_color.jpg";
import jpg2 from "../../images/nano_horz_color-2x.jpg";
import jpg4 from "../../images/nano_horz_color-4x.jpg";

// SCSS
import { logo } from "../../style/style.module.scss";

/*
 * https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
 */
const Logo = (): JSX.Element => (
  <picture className={logo}>
    <source type="image/webp" srcSet={webp} />
    <source type="image/png" srcSet={png2 + " 2x"} />
    <source type="image/png" srcSet={png4 + " 4x"} />
    <source type="image/jpeg" srcSet={jpg1} />
    <source type="image/jpeg" srcSet={jpg2 + " 2x"} />
    <source type="image/jpeg" srcSet={jpg4 + " 4x"} />
    <img src={png1} className={logo} alt="NanoPharmaceuticals Logo" />
  </picture>
);

export default Logo;
