import React, { useState } from "react";
import { Link } from "gatsby";

// Images
import Logo from "./pictures/logo";

// SCSS
import {
  mainMenuItem,
  mainMenuLink,
  header,
  logoLink,
  subMenuList,
  subMenuLink,
} from "../style/style.module.scss";

// Types
export interface MainMenuItem {
  text: string;
  location: string;
  children?: MenuItem[];
}
export interface MenuItem {
  text: string;
  location: string;
}

export const mainMenuData: MainMenuItem[] = [
  {
    text: "Homepage",
    location: "/",
  },
  {
    text: "About Us",
    location: "/about",
    children: [
      {
        text: "Company Overview",
        location: "/about",
      },
      {
        text: "Management/Board",
        location: "/board",
      },
    ],
  },
  {
    text: "Research & Development",
    location: "/science",
    children: [
      {
        text: "Our Science",
        location: "/science",
      },
      {
        text: "Therapeutic Focus",
        location: "/focus",
      },
      {
        text: "Clinical Trials",
        location: "/trials",
      },
      {
        text: "Pipeline",
        location: "/pipeline",
      },
    ],
  },
  {
    text: "Intellectual Property",
    location: "/ip",
  },
  {
    text: "Publications",
    location: "/publications",
  },
  {
    text: "Contact Us",
    location: "/contact",
  },
];

/**
 * Main menu link
 */
const MainMenuLink = ({ item }: { item: MainMenuItem }): JSX.Element => {
  // State
  const [state, setState] = useState<boolean>(false);

  // Sub-menu
  const formatSubMenu = (item: MainMenuItem): JSX.Element => (
    <div className={subMenuList}>
      {item.children
        ? item.children.map((subItem: MenuItem) => (
            <Link
              to={subItem.location}
              className={subMenuLink}
              key={subItem.location}
            >
              {subItem.text}
            </Link>
          ))
        : null}
    </div>
  );

  // Style
  const formatMainMenu = (item: MainMenuItem): JSX.Element => {
    if (item.children) {
      // Make the top-level menu item a dummy link to trigger the dropdown
      return (
        <div
          key={item.location}
          className={mainMenuItem}
          onMouseOver={() => setState(true)}
          onMouseLeave={() => setState(false)}
        >
          <a
            href="#"
            className={mainMenuLink}
            key={item.location}
            onClick={() => setState(!state)}
          >
            {item.text}
          </a>
          {state ? formatSubMenu(item) : null}
        </div>
      );
    } else {
      // Menu items without children should be standard links
      return (
        <div key={item.location} className={mainMenuItem}>
          <Link to={item.location} className={mainMenuLink} key={item.location}>
            {item.text}
          </Link>
        </div>
      );
    }
  };

  // Render
  return formatMainMenu(item);
};

/**
 * Header component
 */
const Header = (): JSX.Element => {
  // Render
  return (
    <header className={header}>
      <Link to="/" className={logoLink}>
        <Logo />
      </Link>

      {mainMenuData.map((val: MenuItem) => (
        <MainMenuLink item={val} />
      ))}
    </header>
  );
};

export default Header;
