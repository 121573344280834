import React from "react";
import { Link } from "gatsby";

// Logo
import Logo from "../components/pictures/logo";

// Interfaces
import { MenuItem, MainMenuItem, mainMenuData } from "./header";

// SCSS
import {
  footer,
  sitemap,
  footerContent,
  footerMain,
  footerSubContent,
  footerFine,
  footerLogo,
} from "../style/style.module.scss";

const year: number = new Date().getFullYear();

const Footer = (): JSX.Element => (
  <footer className={footer}>
    <div className={footerLogo}>
      <Logo />
    </div>

    <div className={sitemap}>
      {mainMenuData.map((item: MainMenuItem) => (
        <ul className={footerContent}>
          <li className={footerMain} key={item.location}>
            <Link to={item.location}>{item.text}</Link>
          </li>
          {item.children
            ? item.children.map((subItem: MenuItem, index) => (
                <ul key={index} className={footerSubContent}>
                  <li key={subItem.location}>
                    <Link to={subItem.location}>{subItem.text}</Link>
                  </li>
                </ul>
              ))
            : null}
        </ul>
      ))}
    </div>

    <div className={footerFine}>
      © {year} NanoPharmaceuticals. All rights reserved.
    </div>
  </footer>
);

export default Footer;
